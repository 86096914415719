/** @module App */

import React, { Suspense, lazy, useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n";
import Loader from "./components/common/Loader";
import NavbarMain from "./components/NavbarMain";
import { readJwtFromStorage, requestAuthToken } from "./components/common/auth";
import { olgaLayers, pageID } from "./components/common/constants";
const Main = lazy(() => import("./components/main/Main"));
const Olga = lazy(() => import("./components/olga/Olga"));
import "./scss/App.scss";
import "./scss/bootstrapCustom.scss";
import { Button, Modal } from "react-bootstrap";
import { getTitle } from "./components/SEO/metatags";
import NothingFound from "./components/404";
import TermsRu from "./components/termsRu";
import TermsEn from "./components/termsEn";
import PrivacyPolicyEn from "./components/privacyPolicyEn";
import PrivacyPolicyRu from "./components/privacyPolicyRu";
import Footer from "./components/footer";

const App = () => {
  /**
   * Создает структуру раутинга и разделение кода.
   *
   * @property {object} olgaAstroData - данные с результатами расчета от сервера
   * @property {object} chartDetails данные хорарной карты - координаты и заметки
   * @property {string} pageType Тип страницы - главная, блог или астропроцессор
   * @property {string} searchKeys ключи гет-запроса одной строкой
   * @property {string} currLayer - текущий отображаемый "слой" программы Ольга
   * @property {object} decodedJwt - расшифрованное значение JSON web токена
   * @property {number} sharedAccessLevel - уровень доступа в shared-ссылке
   * @property {string} serverError сообщение от сервера об ошибке при загрузке
   * данных по sharing-ссылке
   * @property {boolean} isCopyMessageExpanded открыто ли модальное окно с информацией
   * об успешном копировании ссылки на карту в буфер обмена.
   * @property {string} currLang текущий язык приложения
   * @property {object} serverPageData данные о содержимом страницы (категории, поста, статической
   * страницы), пришедшие от сервера, и включающие SEO данные
   * @property {string} partner идентификатор партнера
   * @returns {React.ReactElement}
   */

  const url = window.location.pathname;
  const searchParams = window.location.search;

  const [olgaAstroData, setOlgaAstroData] = useState({});
  const [chartDetails, setChartDetails] = useState({});
  const [pageType, setPageType] = useState(pageID.main);
  const [searchKeys] = useState(searchParams);
  const [currLayer, setCurrLayer] = useState(
    window.location.pathname.includes("/astrology-software/")
      ? olgaLayers.upgrade.menuItem
      : olgaLayers.chart.new
  );
  const [decodedJwt, setDecodedJwt] = useState(readJwtFromStorage());
  const [sharedAccessLevel, setSharedAccesLevel] = useState(-10);
  const [serverError, setServerError] = useState("");
  const [isCopyMessageExpanded, setIsCopyMessageExpanded] = useState(false);
  const [currLang, setCurrLang] = useState("en");
  const [currURL, setCurrURL] = useState(url);
  const [partner, setPartner] = useState("");
  const [theme, setTheme] = useState("light");
  const [redrawComponent, setRedrawComponent] = useState(false);

  const refTop = useRef(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation(["translationNavbar"]);

  useEffect(() => {
    /**
     * Устанавливает тип текущей страницы.
     * Используеися в навигацйионной панелши
     * для подсветки активных пунктов, а также
     * в блоке SEO для загрузки метатэгов для
     * статических страниц из файлов
     */
    let pType = "";
    if (url === "/en/" || url === "/ru/" || url === "/") {
      pType = pageID.main;
    } else if (url.includes("/olga/")) {
      pType = pageID.olga;
    } else if (
      [
        "/terms/",
        "/astrology-software/",
        "/astrology-software/ru/",
        "/astrology-software/en/",
        "/terms/",
        "/terms/ru/",
        "/terms/en/",
        "/privacy/",
        "/privacy/en/",
        "/privacy/ru/",
      ].includes(url)
    ) {
      pType = pageID.static;
    } else {
      pType = pageID.e404;
    }

    setPageType(pType);
    console.log(pType);

    /**
     * SEO: Обновляет заголовок страницы
     * при каждой смене URL.
     */
    const title = getTitle(pType);

    document.querySelector("title").textContent = title;
  }, [currURL]);

  useEffect(() => {
    /**
     * При первой загрузке страницы отправляет запрос на сервер
     * на аутентификацию пользователя (если есть нужные ключи
     * в гет-запросе).
     */
    if (url.includes("/olga/auth") && searchKeys) {
      const searchParams = new URLSearchParams(searchKeys);
      if (searchParams.has("key1") && searchParams.has("key2")) {
        requestAuthToken(searchKeys, (decodedToken) =>
          setDecodedJwt(decodedToken)
        );
      }
    }

    /**
     * Принудительно установает язык в зависимости от
     * суффикса /en/ или /ru/ в URL.
     */
    if (window.location.pathname.includes("/en/") && getLang() !== "en") {
      changeLang("en");
    } else if (
      window.location.pathname.includes("/ru/") &&
      getLang() !== "ru"
    ) {
      changeLang("ru");
    } else {
      changeLang(getLang());
    }

    /**
     * Устанавливаем идентификатор партнера
     */
    const partnerID = new URLSearchParams(searchParams).get("partner");
    if (partnerID) setPartner(partnerID);

    /**
     * Меняем текущую тему в зависимости от
     * настроект браузера
     */
    // Сначала пытается определить сохраненные установки
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme && ["dark", "light", "auto"].includes(savedTheme)) {
      switchTheme(savedTheme);
    } else {
      switchTheme(autoDetectTheme());
    }
  }, []);

  function switchTheme(requestedTheme) {
    const newTheme =
      requestedTheme === "auto" ? autoDetectTheme() : requestedTheme;
    const rootElement = document.documentElement;
    localStorage.setItem("theme", requestedTheme);
    rootElement.setAttribute("data-bs-theme", newTheme);
    setTheme(newTheme);
    // Всегда вызываем перерисовку компоненты, даже если requestedTheme = auto
    // и autodetectTheme() === theme, так как у нас должна поменяться
    // иконка текущей темы в навбаре на auto.
    setRedrawComponent(!redrawComponent);
  }

  function autoDetectTheme() {
    /**
     * Автоматически определяет тему браузера
     */
    const isDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    return isDarkMode ? "dark" : "light";
  }

  function scrollTop() {
    /**
     * Вызывает прокрутку вверх до ссылаемого
     * элемента <div ref={refTop} />
     */
    refTop.current.scrollIntoView();
  }

  function changeLang(lng) {
    /**
     * Переключает язык приложения и подтягивает
     * соответствующие переводы. Осуществляется
     * внешним плагином.
     *
     * @callback module:NavbarMain~function:NavbarMain
     * @callback module:NavbarMain~function:NavbarMain~function:UseEffect
     * @param {string} lng текущий язык приложения ("en"/"ru")
     */
    i18n.changeLanguage(lng);
    setCurrLang(lng);
  }

  function getLang() {
    /**
     * Возвращает текущий установленный язык приложения.
     * При первой загрузке язык берется из браузера, либо
     * же переключается на английский по-умолчанию.
     * Реализован внешним плагином.
     *
     * @callback module:NavbarMain~function:NavbarMain
     * @callback module:NavbarMain~function:NavbarMain~function:UseEffect
     * @returns {string} символьный код текущего языка ("en"/"ru")
     */
    const lang = i18n.language.slice(0, 2);
    if (lang === "ru") return "ru";
    return "en";
  }

  // Параметры для навбара
  const passNavBar = {
    currLang,
    changeLang,
    setCurrURL,
    pageType,
    decodedJwt,
    currLayer,
    setCurrLayer,
    setDecodedJwt,
    olgaAstroData,
    chartDetails,
    sharedAccessLevel,
    setSharedAccesLevel,
    setServerError,
    setIsCopyMessageExpanded,
    switchTheme,
  };

  // Параметры для главной страницы
  const passMain = {
    searchKeys,
    scrollTop,
    currLang,
    partner,
    theme,
  };

  // Параметры для приложения Ольга
  const passOlga = {
    currLang,
    scrollTop,
    currLayer,
    setCurrLayer,
    setCurrURL,
    decodedJwt,
    olgaAstroData,
    setOlgaAstroData,
    chartDetails,
    setChartDetails,
    setSharedAccesLevel,
    serverError,
    setServerError,
    theme,
  };

  return (
    <React.Fragment>
      <div ref={refTop} className="border pt-2" />
      <header>
        {/* Хэдер грузится отдельно и не включен в обертку Suspence.
        Это обеспечивает постоянное присутствие хэдера, пока страничка
        под ним догружается.*/}
        <NavbarMain {...passNavBar} />
      </header>

      <Modal
        show={isCopyMessageExpanded}
        onHide={() => setIsCopyMessageExpanded(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="bi bi-check-circle text-success"></i> {t("Success")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("linkcopied")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setIsCopyMessageExpanded(false)}
          >
            {"Close"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Suspense fallback={<Loader />}>
        <main className="flex-shrink-0 mt-5">
          <Routes>
            <Route path="/en/" element={<Main {...passMain} />} />
            <Route path="/ru/" element={<Main {...passMain} />} />
            <Route path="/olga/en/" element={<Olga {...passOlga} />} />
            <Route path="/olga/ru/" element={<Olga {...passOlga} />} />
            <Route path="/olga/share/en/*" element={<Olga {...passOlga} />} />
            <Route path="/olga/share/ru/*" element={<Olga {...passOlga} />} />
            <Route path="/terms/ru/" element={<TermsRu />} />
            <Route path="/terms/en/" element={<TermsEn />} />
            <Route path="/privacy/en/" element={<PrivacyPolicyEn />} />
            <Route path="/privacy/ru/" element={<PrivacyPolicyRu />} />
            <Route
              path="/astrology-software/ru/"
              element={<Olga {...passOlga} />}
            />
            <Route
              path="/astrology-software/en/"
              element={<Olga {...passOlga} />}
            />

            <Route
              index
              element={
                <Navigate
                  replace
                  to={`/${getLang()}/${window.location.search}`}
                />
              }
            />
            <Route
              path="/olga/auth/*"
              element={
                <Navigate
                  replace
                  to={`/olga/${getLang()}/${window.location.search}`}
                />
              }
            />

            <Route
              path="/olga/"
              element={<Navigate replace to={`/olga/${getLang()}/`} />}
            />
            <Route
              path="/olga/share/*"
              element={
                <Navigate
                  replace
                  to={`/olga/share/${getLang()}/${window.location.search}`}
                />
              }
            />

            {/* Редиректы типа /terms/ -> /terms/en/ */}
            {["/astrology-software/", "/terms/", "/privacy/"].map(
              (slugNoLang) => (
                <Route
                  key={slugNoLang}
                  path={slugNoLang}
                  element={
                    <Navigate replace to={`${slugNoLang}${getLang()}/`} />
                  }
                />
              )
            )}

            <Route path="*" element={<NothingFound currLang={currLang} />} />
          </Routes>
        </main>
      </Suspense>

      <footer className="footer mt-auto pt-3 bg-light">
        <div className="container">
          <Footer currLang={currLang} />
        </div>
      </footer>
    </React.Fragment>
  );
};

export default App;
