import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enNavbar from "./translation-navbar-en.json";
import ruNavbar from "./translation-navbar-ru.json";
import ruOlga from "./translation-olga-ru.json";
import enOlga from "./translation-olga-en.json";
import ruMain from "./translation-main-ru.json";
import enMain from "./translation-main-en.json";
import enBlog from "./translation-blog-en.json";
import ruBlog from "./translation-blog-ru.json";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      ru: {
        translationNavbar: ruNavbar,
        translationOlga: ruOlga,
        translationMain: ruMain,
        translationBlog: ruBlog,
      },
      en: {
        translationNavbar: enNavbar,
        translationOlga: enOlga,
        translationMain: enMain,
        translationBlog: enBlog,
      },
    },
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
