import React from "react";
import { useTranslation } from "react-i18next";
import Spinner from "react-bootstrap/Spinner";

const Loader = () => {
  const { t } = useTranslation(["translationNavbar"]);
  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className="text-center">
        <Spinner animation="border" variant="primary" />
        <p>{t("Loading...")}</p>
      </div>
    </div>
  );
};

export default Loader;
