import axios from "axios";
import { baseURL } from "./serverClient";

export function decodeToken(token) {
  /**
   * Декодирует токен и возвращает его полезную
   * нагрузку в виде объекта
   */
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  const decoded = JSON.parse(jsonPayload);
  if (decoded.access_level)
    decoded.access_level = parseInt(decoded.access_level);
  return decoded;
}

export function readJwtFromStorage() {
  /**
   * Считывает (если есть) JWT из локального хранилища
   * и возвращает его содержимое (расшифрованное).
   * Если токена нет, то возвращает "нулевое" содержимое,
   * соответствующее нулевому уровню доступа.
   * @return {object} расшифрованный токен
   */
  const nullToken = {
    access_level: 0,
    email: "",
    exp: new Date().getTime() / 1000,
  };
  const token = localStorage.getItem("jwt");
  if (!token) return nullToken;

  const now = new Date().getTime() / 1000;
  const decoded = decodeToken(token);

  if (decoded.exp > now) return decoded;
  return nullToken;
}

export async function requestAuthToken(searchKeys, callbackOnSuccess) {
  /**
   * Запрашивает у сервера авторизационный токен.
   * В случае успеха получает валидный токен и извлекает
   * из него информациюо пользователе и времени экспирации
   * сессии. (Эти данные позже извлекаются из локального
   * хранилища, заносятся в переменные состояния и
   * отображаются в навигационной панели программы Ольга).
   */
  const instance = axios.create({
    baseURL: `${baseURL}/api/auth/get/${searchKeys}`,
  });
  let errorMessage = "";
  const response = await instance.get().catch(function (error) {
    errorMessage = error.toJSON().message;
  });
  if (!errorMessage) {
    const token = response.data.jwt;
    if (token) {
      callbackOnSuccess(decodeToken(token));
      localStorage.setItem("jwt", token);
    }
  }
}
