import { pageID } from "../common/constants";
import { t } from "i18next";

export function getTitle(pageType) {
  /**
   * Возвращает все Seo данные для конкретной страницы.
   * @param {string} pageType тип страницы
   */

  const _t = (text) => t(text, { ns: "translationNavbar" });
  const { pathname: path } = window.location;

  if (pageType === pageID.main) {
    return _t("maintitle");
  }

  if (pageType === pageID.olga) {
    return _t("olgatitle");
  }

  if (pageType === pageID.static && path.includes("/astrology-software/")) {
    return _t("olgatitle");
  }

  if (pageType === pageID.static && path.includes("/terms/")) {
    return _t("termstitle");
  }

  if (pageType === pageID.static && path.includes("/privacy/")) {
    return _t("privacytitle");
  }

  return "404. The requested page is not found";
}
