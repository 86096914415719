/**
 * @module NavBarMain
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Nav,
  Navbar,
  NavDropdown,
  Container,
  ButtonGroup,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { saveSvgAsPng } from "save-svg-as-png";
import { olgaLayers, pageID } from "./common/constants";
import { readJwtFromStorage } from "./common/auth";
import logo from "./images/logo.svg";
import { baseURL } from "./common/serverClient";

function NavbarMain(props) {
  /**
   * Отрисовывает главное меню сайта.
   *
   * @param {object} props Данные от родительского объекта module:App~function:App~pass
   * @property {bool} expanded Состояние раскрытости меню в мобильном представлении
   * @property {string} currLang Текущий язык приложения ("en"/"ru")
   * @property {string} currURL текущий URL. Его изменение вызывает епрерисовку навигационного меню
   * @return {React.ReactElement}
   */

  // Переменные родительского объекта
  const {
    currLang,
    changeLang,
    pageType,
    decodedJwt,
    setCurrLayer,
    setDecodedJwt,
    olgaAstroData,
    chartDetails,
    sharedAccessLevel,
    setSharedAccesLevel,
    setServerError,
    setIsCopyMessageExpanded,
    currLayer,
    setCurrURL,
    switchTheme,
  } = props;

  const [expanded, setExpanded] = useState(false);

  // функция языкового модуля
  const { t } = useTranslation(["translationNavbar"]);

  // С какого размера меню переходит в мобильный вид.
  const breakpoint = "md";

  // Список меню основного сайта.
  const menu = [
    { name: "Home", link: `/${currLang}/`, id: pageID.main },
    // { name: "For Astrologers", link: `/olga/${currLang}/`, id: pageID.olga },
    { name: "For Astrologers", link: `https://chart.astro-school.org/`, id: pageID.olga },
    { name: "Blog", link: `/blog/${currLang}/`, id: pageID.blog },
  ];

  function collapse() {
    /**
     * Переводит меню в свернутое состояние
     */
    setExpanded(false);
  }

  function switchState() {
    /**
     * Меняет состояние свернутое/развернутое для
     * мобильного представления меню при нажатии
     * на переключатель свернутости меню.
     */
    setExpanded(expanded ? false : true);
  }

  function changeTheme(theme) {
    switchTheme(theme);
    collapse();
  }

  function detectAccessLevel() {
    /**
     * Определяет уровень доступа. По-умолчанию,
     * доступ берется из токена, но если доступ
     * по ссылке не равен уровню доступ токена, то
     * берется уровень доступа из ссылки
     */
    const timeLeft = Math.round(
      (decodedJwt.exp - Math.floor(new Date().getTime() / 1000)) / 3600
    );
    const access_level_from_JWT =
      timeLeft >= 0 ? parseInt(decodedJwt.access_level) : 0;
    const access_level_from_link = sharedAccessLevel;

    let level = access_level_from_JWT;
    let type = "jwt";

    // Если приложение открыто по ссылке
    if (access_level_from_link >= 0) {
      level = access_level_from_link;
      type = "link";
    }

    let access = {
      level,
      type,
      timeLeft,
      access_level_from_JWT,
    };

    return access;
  }

  function displayBrand() {
    /**
     * Отображает название приложения "Ольга" и рядом
     * бейдж текущей версии
     */
    const names = ["DEMO", "PREMIUM", "PLATINUM"];

    if (pageType !== pageID.olga) return "Pocket Astrologer";

    const access = detectAccessLevel();
    const version = names[access.level];

    let subTitle = "";
    if (access.type === "link") {
      subTitle = t("Access granted by link");
    } else if (access.level > 0) {
      subTitle = access.timeLeft
        ? `${access.timeLeft} ${t("hrs. till logout")}`
        : `<1 ${t("hr. till logout")}`;
    } else {
      subTitle = t("limited version");
    }

    return (
      <span className="d-flex align-items-center">
        OLGA
        <small
          className="d-inline-block fw-light ps-2 ms-2 border-start border-secondary"
          style={{ lineHeight: "80%" }}
        >
          {version}
          <br />
          <span style={{ fontSize: ".7rem" }}>{subTitle}</span>
        </small>
      </span>
    );
  }

  function onOlgaSubmenuClick(menuItem, submenuItem) {
    /**
     * Устанавливает действия при нажатии
     * на пункт выпадающего меню программы
     * Ольга.
     *
     * @param {string} menuItem - название пункта меню
     * @param {string} submenuItem - название пункта подменю
     */
    collapse();
    setServerError("");
    if (submenuItem === "new") {
      setDecodedJwt(readJwtFromStorage());
      setSharedAccesLevel(-10);
      setCurrLayer(olgaLayers[menuItem][submenuItem]);
    } else if (submenuItem === "asImage") {
      const chartDOMElement = document.getElementById("mainChart");
      const name =
        `chart${chartDetails.year}-${chartDetails.month}` +
        `-${chartDetails.day}__${chartDetails.hour}-${chartDetails.minute}.png`;
      saveSvgAsPng(chartDOMElement, name);
    } else if (submenuItem === "share") {
      navigator.clipboard.writeText(
        `${baseURL}/olga/share/${currLang}/?${olgaAstroData.share_link}`
      );
      setIsCopyMessageExpanded(true);
    } else {
      setCurrLayer(olgaLayers[menuItem][submenuItem]);
    }
  }

  function displayOlgaDropDownMenu(menuItem) {
    /**
     * Отображает пункт меню с иконкой
     * для приложения Ольга.
     *
     * @param {string} menuItem - название пункта меню
     */
    const clsName = `fs-2 bi ${olgaLayers[menuItem].menuIcon} d-block mx-auto my-0 py-0`;
    return (
      <div className="text-secondary text-center lh-1">
        <i className={clsName}></i>
        <small>{t(olgaLayers[menuItem].menuItem)}</small>
      </div>
    );
  }

  function insertOlgaDivider(menuItem, submenuItem) {
    /**
     * Вставляет разделители в подменю
     * пунктов меню программы Ольга
     *
     * @param {string} menuItem - название пункта меню
     * @param {string} submenuItem - название пункта подменю
     */
    const dividerPlaces = [
      olgaLayers.chart.open,
      olgaLayers.chart.asImage,
      olgaLayers.analysis.radicality,
      olgaLayers.analysis.stars,
      olgaLayers.analysis.signs,
      olgaLayers.aspects.antiscia,
    ];
    const isInsert = dividerPlaces.includes(olgaLayers[menuItem][submenuItem]);
    return isInsert ? <NavDropdown.Divider /> : null;
  }

  function isOlgaSubmenuDisabled(menuItem, submenuItem) {
    /**
     * Определяет недоступен ли данный пункт
     * подменю программы Ольга для текущего
     * уровня пользователя
     *
     * @param {string} menuItem - название пункта меню
     * @param {string} submenuItem - название пункта подменю
     */

    const { level: access_level, access_level_from_JWT } = detectAccessLevel();

    const item = olgaLayers[menuItem][submenuItem];
    if (olgaLayers.chart.open === item) {
      return access_level_from_JWT == 0;
    } else if (olgaLayers.chart.edit == item) {
      return (
        access_level_from_JWT == 0 || Object.keys(olgaAstroData).length === 0
      );
    } else if (olgaLayers.chart.asImage === item) {
      return (
        Object.keys(olgaAstroData).length === 0 ||
        [olgaLayers.chart.new, olgaLayers.upgrade.menuItem].includes(currLayer)
      );
    } else if (olgaLayers.chart.share == item) {
      return Object.keys(olgaAstroData).length === 0;
    } else if (
      [
        olgaLayers.analysis.radicality,
        olgaLayers.analysis.parts,
        olgaLayers.analysis.stars,
        olgaLayers.aspects.exact,
        olgaLayers.aspects.longterm,
        olgaLayers.aspects.close,
        olgaLayers.aspects.antiscia,
        olgaLayers.aspects.allaspects,
      ].includes(item)
    ) {
      return access_level === 0;
    } else if (
      [
        olgaLayers.naturopathy.symptoms,
        olgaLayers.naturopathy.healing,
        olgaLayers.analysis.elections,
      ].includes(item)
    ) {
      return access_level < 2;
    }
  }

  // function isMenuItemActive(menuId) {
  //   if (pageType === menuId) {
  //     return "text-primary";
  //   }
  //   if (pageType === pageID.post && menuId === pageID.blog) {
  //     return "text-primary";
  //   }
  //   return "";
  // }

  const savedTheme = localStorage.getItem("theme");
  const themeAuto = <i className="bi bi-circle-half me-2 opacity-75"></i>;
  const themeDark = <i className="bi bi-moon-stars-fill me-2 opacity-75"></i>;
  const themeLight = <i className="bi bi-sun-fill me-2 opacity-75"></i>;

  let currentThemeIcon = themeAuto;
  if (savedTheme === "dark") currentThemeIcon = themeDark;
  else if (savedTheme === "light") currentThemeIcon = themeLight;

  return (
    <Navbar
      expanded={expanded}
      onToggle={switchState}
      expand={breakpoint}
      fixed="top"
      className="shadow navbar-solid"
    >
      <Container>
        <Navbar.Brand
          as={Link}
          to={`/${currLang}/`}
          className="fw-semibold d-flex align-items-center"
          onClick={() => {
            setCurrLayer(olgaLayers.chart.new);
            setSharedAccesLevel(-10);
            // Для определение типа страницы и
            // заполнения SEO данными на стороне родителя
            setCurrURL("/");
          }}
        >
          <img
            src={logo}
            alt="Pocket Astrologer"
            width="33"
            height="33"
            className="d-inline-block align-text-middle me-3 "
          />
          {displayBrand()}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className={`d-inline-flex-${breakpoint} justify-content-end`}
        >
          {pageType !== pageID.olga ? (
            // Главное меню сайта
            <Nav className="me-md-3">
              {menu.map((item) => <Nav.Link
                href={item.link}
                key={item.id}
                className={`px-${breakpoint}-3 active`}
              >
                {t(item.name)}
              </Nav.Link>
              )}

              {/*menu.map((item) =>
                item.id === pageID.blog ? (
                  <Nav.Link
                    href={item.link}
                    key={item.id}
                    className={`px-${breakpoint}-3 active`}
                  >
                    {t(item.name)}
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    as={Link}
                    to={item.link}
                    key={item.id}
                    className={`px-${breakpoint}-3 active ${isMenuItemActive(
                      item.id
                    )}`}
                    onClick={() => {
                      collapse();
                      setCurrLayer(olgaLayers.chart.new);
                      // Для определение типа страницы и
                      // заполнения SEO данными на стороне родителя
                      setCurrURL(item.link);
                    }}
                  >
                  {t(item.name)}
                  </Nav.Link>
                )
                  )*/}
            </Nav>
          ) : (
            // Меню приложения Ольга
            <Nav className="me-md-3">
              {Object.keys(olgaLayers).map((dropdown) => {
                if (dropdown !== "upgrade")
                  return (
                    <NavDropdown
                      key={dropdown}
                      disabled={
                        Object.keys(olgaAstroData).length === 0 &&
                        dropdown !== "chart"
                      }
                      title={displayOlgaDropDownMenu(dropdown)}
                    >
                      {Object.keys(olgaLayers[dropdown]).map((submenu) => {
                        if (!["menuItem", "menuIcon"].includes(submenu))
                          return (
                            <React.Fragment key={submenu}>
                              <NavDropdown.Item
                                disabled={isOlgaSubmenuDisabled(
                                  dropdown,
                                  submenu
                                )}
                                href="#"
                                onClick={() =>
                                  onOlgaSubmenuClick(dropdown, submenu)
                                }
                              >
                                {t(olgaLayers[dropdown][submenu])}
                              </NavDropdown.Item>
                              {insertOlgaDivider(dropdown, submenu)}
                            </React.Fragment>
                          );
                      })}
                    </NavDropdown>
                  );
              })}

              {/* Отдельный пункт меню апгрейда */}
              {decodedJwt.access_level === 0 &&
                Object.keys(olgaAstroData).length > 0 && (
                  <Nav.Link
                    href="#"
                    onClick={() => {
                      setCurrLayer(olgaLayers.upgrade.menuItem);
                      collapse();
                    }}
                  >
                    <div className="text-secondary text-center lh-1">
                      <i className="fs-2 bi bi-unlock d-block mx-auto my-0 py-0"></i>
                      <small>{t(olgaLayers.upgrade.menuItem)}</small>
                    </div>
                  </Nav.Link>
                )}
            </Nav>
          )}

          <div className="d-flex justify-content-center align-items-center">
            {/* Переключение темы */}
            <NavDropdown
              title={currentThemeIcon}
              id="theme-nav-dropdown"
              className="me-3"
            >
              <NavDropdown.Item href="#" onClick={() => changeTheme("light")}>
                {themeLight} Light
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => changeTheme("dark")}>
                {themeDark} Dark
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => changeTheme("auto")}>
                {themeAuto} Auto
              </NavDropdown.Item>
            </NavDropdown>

            {/* Переключение языков */}
            <ButtonGroup className={`py-3 py-${breakpoint}-0`}>
              {["ru", "en"].map((lang) => {
                let { pathname } = window.location;
                if (pathname.includes("/en/")) {
                  pathname = pathname.replace("/en/", `/${lang}/`);
                } else if (pathname.includes("/ru/")) {
                  pathname = pathname.replace("/ru/", `/${lang}/`);
                }
                return (
                  <Button
                    as={Link}
                    to={pathname}
                    onClick={() => {
                      changeLang(lang);
                      collapse();
                      /**
                       * Смена URL вызывает динамическое переопределение
                       * SEO данных и новую подстановку метатэгов
                       */
                      setCurrURL(pathname);
                    }}
                    variant={currLang == lang ? "primary" : "outline-primary"}
                    size="sm"
                    key={lang}
                  >
                    {lang[0].toUpperCase() + lang.substring(1)}
                  </Button>
                );
              })}
            </ButtonGroup>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

NavbarMain.propTypes = {
  /**
   * Текущий язык приложения
   */
  currLang: PropTypes.string,
  changeLang: PropTypes.func,
  /**
   * Тип страницы - главная, астропроцессор или блог
   */
  pageType: PropTypes.string,
  /**
   * Ключи в URL запросе
   */
  searchKeys: PropTypes.string,
  /**
   * Текущий "слой" программы Ольга
   * Управляется модуляем App.jsx
   */
  currLayer: PropTypes.string,
  setCurrLayer: PropTypes.func,
  /**
   * Результаты астрологических расчетов
   */
  olgaAstroData: PropTypes.object,
  /**
   * Декодированный токен с уровнем доступа и
   * временем экспирации
   */
  decodedJwt: PropTypes.object,
  setDecodedJwt: PropTypes.func,
  /**
   * Детали хорара
   */
  chartDetails: PropTypes.object,
  /**
   * Уровень доступа из shared-ссылки
   */
  sharedAccessLevel: PropTypes.number,
  setSharedAccesLevel: PropTypes.func,
  /**
   * Сообщение об ошибке при загрузке
   * данных по sharing-ссылке
   */
  setServerError: PropTypes.func,
  /**
   * Записывает состояние модального
   * окна с сообщение о скопированной
   * ссылке в переменную состояния
   * родительского объекта
   */
  setIsCopyMessageExpanded: PropTypes.func,
  /**
   * Состояние переключателя языков
   */
  langSwitchDisabled: PropTypes.bool,
  setLangSwitchDisabled: PropTypes.func,
  /**
   * Заносит текущий URL в переменную состояния родителя.
   * Это вызывает функцию определения типа страницы
   * и заполнение SEO данными на стороне родителя
   */
  setCurrURL: PropTypes.func,
  switchTheme: PropTypes.func,
};

export default NavbarMain;
