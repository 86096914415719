import axios from "axios";
// export const baseURL = "http://localhost";
export const baseURL = "https://pocket-astrologer.com";
export const jwt0 =
  "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NfbGV2ZWwiOiIwIiwiZW1haWwiOiJpbmZvQGFzdHJvLXNjaG9vbC5vcmciLCJleHAiOjE2NjA4MDcxNTd9.BzcrW82y1oG7n_f_cLhNHOEAfTBblmDU9vx8NdwkZUAgJI29oyIRlckyONpByBB4BzYG2xjB5M0IUT-Px9J7XQ";

export const sendMethod = { get: "GET", post: "POST" };

export async function sendDataToServer(
  method,
  url,
  payload,
  callbackOnSuccess,
  callbackOnError = null
) {
  /**
   * Универсальная функция отправки данных
   * на сервер.
   * @param {string} method POST или GET
   * @param {string} url
   * @param {object} payload данные POST запроса
   * @param {function} callbackOnSuccess
   * @param {function} callbackOnError
   */

  const instance = axios.create();
  const jwt = localStorage.getItem("jwt") ? localStorage.getItem("jwt") : jwt0;
  const csrftoken = getCookie("csrftoken");

  instance.defaults.headers["jwt"] = jwt;
  if (csrftoken) instance.defaults.headers["X-CSRFToken"] = csrftoken;

  if (method === sendMethod.post) {
    const { data } = await instance
      .post(url, payload)
      .catch((error) => callbackOnError(error.toJSON().message));
    callbackOnSuccess(data);
  } else {
    const { data } = await instance
      .get(url)
      .catch((error) => callbackOnError(error.toJSON().message));
    callbackOnSuccess(data);
  }
}

export function getCookie(name) {
  /**
   * Возвращает содержимое куки с заданным именем,
   * либо null, если такого куки не найдено.
   */
  let cookieValue = null;

  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));

        break;
      }
    }
  }

  return cookieValue;
}

export async function requestListOfCities(
  firstLetters,
  lang,
  callbackOnSuccess,
  callbackOnError
) {
  /**
   * Отправляет асинхронный запрос на список
   * городов от сервера при вводе буквы города.
   * Полученные данные отправляет на обработку
   * в функцию callbackOnSuccess.
   * @param {string} lang текущий язык приложения ('en', 'ru')
   * @param {function} callbackOnSuccess функция, куда отправляется полученный список городов
   * @param {function} callbackOnError функция, куда отправляется полученный список городов
   * @param {string} firstLetters элемент поля с введенными буквами
   * @param {function} callbackOnSuccess - функция для обработки полученных данных
   * @param {function} callbackOnError - функция для отображения ошиюкт сервера
   */
  if (firstLetters.length === 0) return null;
  const link = `${baseURL}/api/cities/?startswith=${firstLetters}&lang=${lang}`;
  sendDataToServer(
    sendMethod.get,
    link,
    {},
    callbackOnSuccess,
    callbackOnError
  );
}
