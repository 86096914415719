import React from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";

const PrivacyPolicyRu = () => {
  return (
    <Container className="my-3 my-md-5">
      <Row className="post">
        <Col xs={12} md={2} />
        <Col xs={12} md={8}>
          <h1>Политика конфиденциальности</h1>
          <hr className="w-25" />
          <p className="pt-0 pb-4">
            <small className="text-muted">
              ИП Геттуева Е.П. ИНН 071002993304
            </small>
          </p>

          <h2>1. Общие положения</h2>
          <p>
            1.1. Настоящее Положение об обработке и защите персональных данных
            (далее Положение) в проекте Индивидуального предпринимателя
            Геттуевой Елены Петровны (далее — Оператор, Проект) разработано в
            соответствии с Конституцией РФ, Гражданским кодексом РФ, Федеральным
            законом от 27 июля 2006 г. № 149-ФЗ «Об информации, информационных
            технологиях и о защите информации», Федеральным законом от 27 июля
            2006 г. № 152-ФЗ «О персональных данных» (далее — Федеральный закон
            «О персональных данных») и принятыми в соответствии с ними
            нормативными правовыми актами РФ.
          </p>
          <p>
            1.2. Целью разработки настоящего Положения является определение
            порядка сбора, записи, систематизации, накопления, хранения,
            уточнения (обновления, изменения), извлечения, использования,
            передачи (распространения, предоставления, доступа), обезличивания,
            блокирования, удаления, уничтожения персональных данных,
            обрабатываемых Оператором при оказании услуг.
          </p>
          <p>
            1.3. Настоящее Положение применяется ко всем сайтам Оператора,
            содержащим ссылки на данное Положение, независимо от способа их
            использования или доступа, включая доступ с мобильных устройств, в
            том числе: https://pocket-astrologer.com/, включая все поддомены
            (далее — Сайт).
          </p>
          <p>
            1.4. Настоящим Положением получатель услуг Оператора либо посетитель
            Сайта как субъект персональных данных уведомлен и дает свое согласие
            о возникающей в процессе работы Сайта и получения услуг Оператора
            объективной необходимости разрешить доступ к своим персональным
            данным для программных средств Оператора и третьих лиц (партнеров
            или поставщиков услуг Оператора). Данный доступ обеспечивается
            исключительно для целей, определенных настоящим Положением (п.
            3.1.1.).
          </p>
          <p>
            1.5. В случае несогласия субъекта персональных данных полностью либо
            в части с условиями настоящего Положения – использование Сайта и его
            сервисов должно быть немедленно прекращено.
          </p>
          <h2>2. Состав персональных данных</h2>
          <p>
            2.1. Оператор обрабатывает персональные данные физических лиц,
            которые получили доступ к информационным материалам Сайта на
            возмездной основе.
          </p>
          <p>
            2.2. Перечень персональных данных, которые субъект предоставляет
            самостоятельно:
          </p>
          <ul>
            <li>адрес электронной почты (e-mail);</li>
          </ul>

          <p>
            2.3. На Сайте Оператора используются автоматизированные средства
            сбора данных — куки (Cookies) и данные о посетителях от сервисов
            статистики (IP адрес, информация о браузере, время доступа к сайту,
            адрес страницы, реферер (адрес предыдущей страницы).
          </p>
          <p>
            Субъект персональных данных может в любой момент изменить настройки
            своего браузера так, чтобы все файлы cookie блокировались или
            осуществлялось оповещение об их отправке. При этом субъект должен
            понимать, что некоторые функции и сервисы Проекта не смогут работать
            должным образом.
          </p>
          <p>
            Файлы куки (Cookies) используются для защиты доступа к
            видео-материалам и для сохранения сессии работы с материалами,
            расположенными на Сайте.
          </p>
          <p>
            2.4. Оператор не осуществляет намеренно обработку персональных
            данных несовершеннолетних лиц. Оператор рекомендует пользоваться
            сайтом лицам, достигшим 18 лет. Ответственность за действия
            несовершеннолетних, включая приобретение ими услуг на Сайте, лежит
            на законных представителях несовершеннолетних. Все посетители,
            младше 18 лет, обязаны получить разрешение своих законных
            представителей прежде, чем предоставлять какую-либо персональную
            информацию о себе.
          </p>
          <p>
            Если Оператору станет известно о том, что он получил персональную
            информацию о несовершеннолетнем лице без согласия законных
            представителей, то такая информация будет удалена в возможно
            короткие сроки.
          </p>
          <h2>3. Обработка персональных данных</h2>
          <h3>3.1. Общие требования при обработке персональных данных</h3>

          <p>
            3.1.1. Обработка персональных данных осуществляется Оператором в
            следующих целях:
          </p>
          <ul>
            <li>
              а) исполнения обязательств по договору-оферте, опубликованному на
              Сайте;
            </li>

            <li>
              в) для обнаружения, предотвращения, смягчения последствий и
              расследования мошеннических или незаконных действий в отношении
              Оператора.
            </li>
          </ul>

          <p>
            3.1.2. Обработке подлежат только те персональные данные, которые
            отвечают целям их обработки (п.3.1.1.). Персональные данные не могут
            быть использованы в целях причинения имущественного и морального
            вреда субъектам персональных данных.
          </p>
          <h3>3.2. Получение персональных данных</h3>

          <p>
            3.2.1. Обработка персональных данных происходит как автоматически,
            так и предоставляется субъектом персональных данных согласно п. 2.2
            и 2.3. настоящего Положения.
          </p>
          <p>
            3.2.2. Субъект самостоятельно принимает решение о предоставлении
            своих персональных данных и дает согласие на их обработку Оператором
            свободно, своей волей и в своем интересе.
          </p>
          <p>
            3.2.3. Согласие, указанное в п. 3.2.2 настоящего Положения, также
            означает согласие на обработку своих персональных данных третьими
            лицами, согласие субъекта на трансграничную передачу данных
            посредством сети Интернет (когда такая передача необходима для
            эффективного оказания услуг либо необходима для достижения иных
            целей, установленных настоящим Положением), а также на получение
            email-рассылки для получения рекламных и маркетинговых материалов
            Проекта и sms-уведомлений о статусах заказа, сделанных на Сайте
            Оператора.
          </p>
          <p>
            При этом под трансграничной передачей данных Стороны понимают
            передачу данных третьим лицам как в странах с адекватным уровнем
            защиты данных, так и не относящихся к таким странам. Необходимый
            уровень защиты персональных данных в любом случае обеспечивается
            Оператором путем соблюдения условий, указанных в п. 3.4 настоящего
            Положения.
          </p>
          <p>
            3.2.4. Предоставление персональных данных и согласие на их обработку
            предоставляется при осуществлении оплаты услуг по договору-оферте
            (акцепте публичной оферты) путем проставления “галочки” в
            специальном “чекбоксе” “Получить квитанцию об оплате по эдектронной
            почте” и является добровольным. При этом отдельное письменное
            согласие не требуется.
          </p>
          <p>
            3.2.5. Согласие на обработку персональных данных может быть отозвано
            субъектом персональных данных в любое время путем обращения в Центр
            технической поддержки, адрес которого указан в разделе 8 настоящего
            Положения.
          </p>

          <h3>
            3.3. Права и обязанности сторон при обработке персональных данных
          </h3>

          <p>
            3.3.1. Субъекты персональных данных обязаны предоставлять Оператору
            только достоверные персональные данные и своевременно сообщать об
            изменении своих персональных данных. При этом Оператор не проверяет
            достоверность персональных данных, и не осуществляет контроль за
            дееспособностью субъектов персональных данных, и исходит из того,
            что субъект предоставляет достоверную и достаточную персональную
            информацию по вопросам, предлагаемым в форме регистрации (подписки,
            оплаты), и поддерживает эту информацию в актуальном состоянии.
          </p>
          <p>
            Риск предоставления недостоверных персональных данных при этом несет
            сам субъект персональных данных.
          </p>
          <p>3.3.2. Каждый субъект персональных данных имеет право:</p>
          <ul>
            <li>
              на получение полной информации о своих персональных данных и на
              свободный бесплатный доступ к своим персональным данным, за
              исключением случаев, предусмотренных действующим
              законодательством;
            </li>
            <li>
              на получение информации, касающейся обработки его персональных
              данных,
            </li>
            <li>
              требовать от Оператора уточнения своих персональных данных, их
              блокирования или уничтожения в случае, если персональные данные
              являются неполными, устаревшими, неточными, незаконно полученными
              или не являются необходимыми для заявленной цели обработки, а
              также принимать предусмотренные законом меры по защите своих прав;
            </li>
            <li>иные права, предусмотренные законодательством РФ.</li>
          </ul>
          <p>
            3.3.3. Субъект персональных данных вправе внести необходимые
            изменения в персональные данные, указанные при оплате на Сайте,
            путем направления соответствующего заявления в Центр технической
            поддержки либо самостоятельно в личном кабинете в разделе
            редактирования профиля (закрытый раздел Сайта).
          </p>
          <p>
            3.3.4. Оператор обязан безвозмездно предоставить субъекту
            персональных данных возможность ознакомления с персональными
            данными, относящимися к этому субъекту, а также внести в них
            необходимые изменения при предоставлении субъектом персональных
            данных сведений, подтверждающих, что персональные данные являются
            неполными, устаревшими, неточными или незаконно полученными. О
            внесенных изменениях и предпринятых мерах Оператор обязан уведомить
            субъекта или его представителя и принять разумные меры для
            уведомления третьих лиц, которым персональные данные этого субъекта
            были переданы.
          </p>
          <p>
            3.3.5. Рассмотрение запроса субъекта по поводу его персональных
            данных осуществляется Оператором в течение 30 (тридцати) календарных
            дней с момента такого обращения, если иной срок не установлен
            настоящим Положением.
          </p>
          <p>
            При этом вся переписка по таким запросам осуществляется через службу
            поддержки Проекта путем отправки сообщений на email субъекта
            персональных данных, указанный при обращении.
          </p>
          <h3>3.4. Передача персональных данных</h3>

          <p>
            3.4.1. В целях эффективной обработки персональных данных,
            надлежащего исполнения заключенного между Оператором и субъектом
            персональных данных договором Проект вправе поручить обработку
            персональных данных другим юридическим или физическим лицам, в том
            числе путем трансграничной передачи данных посредством сети
            Интернет. При этом отдельного согласия субъекта персональных данных
            на такую передачу не требуется.
          </p>
          <p>
            3.4.2. Передача персональных данных субъектов, с которыми
            взаимодействует Проект, осуществляется только для надлежащего
            исполнения обязательств по договору-оферте, в рамках которого
            Оператор и указанные субъекты взаимодействуют.
          </p>
          <h3>3.5. Хранение персональных данных</h3>

          <p>
            3.5.1. Хранение персональных данных осуществляется в электронной
            форме в соответствующих информационных системах персональных данных,
            размещаемых в базах данных на территории РФ.
          </p>
          <p>
            3.5.2. Хранение персональных данных осуществляется в форме,
            позволяющей определить субъекта персональных данных в сроки,
            обеспечивающим соблюдение и достижение целей обработки персональных
            данных, установленные настоящим Положением.
          </p>
          <p>
            3.5.2. Хранение персональных данных осуществляется с ограничением
            доступа, в том числе путем создания соответствующих уровней доступа.
          </p>
          <p>
            3.5.3. Персональные данные, содержащиеся в разных электронных базах
            и обработка которых осуществляется для различных целей, хранятся
            раздельно.
          </p>
          <h3>3.6. Прекращение обработки и уничтожение персональных данных</h3>

          <p>
            3.6.1. В случае выявления неточных персональных данных при обращении
            субъекта персональных данных Проект обязан осуществить блокирование
            персональных данных, относящихся к этому субъекту персональных
            данных, с момента такого обращения на период проверки, если
            блокирование персональных данных не нарушает права и законные
            интересы субъекта персональных данных или третьих лиц.
          </p>
          <p>
            3.6.2. В случае подтверждения факта неточности персональных данных
            Оператор на основании сведений, представленных субъектом
            персональных данных, обязан уточнить персональные данные в течение 7
            (семи) рабочих дней со дня представления таких сведений и снять
            блокирование персональных данных.
          </p>
          <p>
            3.6.3. В случае выявления неправомерной обработки персональных
            данных, осуществляемой Оператором, последний в срок, не превышающий
            3 (трех) рабочих дней со дня этого выявления, обязан прекратить
            неправомерную обработку персональных данных.
          </p>
          <p>
            В случае если обеспечить правомерность обработки персональных данных
            невозможно, Оператор в срок, не превышающий 10 (десяти) рабочих дней
            со дня выявления неправомерной обработки персональных данных, обязан
            уничтожить такие персональные данные. Об устранении допущенных
            нарушений или об уничтожении персональных данных Оператор обязан
            уведомить субъекта персональных данных.
          </p>
          <p>
            3.6.4. В случае отзыва субъектом персональных данных согласия на их
            обработку Оператор обязан прекратить их обработку и в случае, если
            сохранение персональных данных более не требуется для целей
            обработки персональных данных, уничтожить персональные данные в
            срок, не превышающий 30 (тридцати) рабочих дней со дня поступления
            указанного отзыва.
          </p>
          <p>
            3.6.5. Оператор вправе продолжить использовать персональные данные о
            субъекте по итогу рассмотрения отзыва согласия на их обработку,
            обеспечив обезличивание такой информации.
          </p>
          <p>
            3.6.6. Оператор направляет уведомление о результатах рассмотрения
            запросов субъектов персональных данных, указанные в настоящем
            разделе, через Центр технической поддержки путем отправки сообщений
            на email субъекта персональных данных, указанный в запросе.
          </p>
          <h2>4. Защита персональных данных</h2>

          <p>
            4.1. Оператор при обработке персональных данных принимает
            необходимые правовые, организационные и технические меры от
            неправомерного или случайного доступа к ним, уничтожения, изменения,
            блокирования, копирования, предоставления, распространения
            персональных данных, а также от иных неправомерных действий в
            отношении персональных данных.
          </p>
          <p>
            4.2. Обеспечение безопасности персональных данных достигается, в
            частности за счет:
          </p>
          <ul>
            <li>
              использования SSL сертификатов, защищенных соединений,
              проксирующих серверов;
            </li>
            <li>
              использования новейших средств защиты от хакерских атак и вирусов;
            </li>
            <li>
              разработанных политик блокировки IP при попытке войти в
              несуществующий аккаунт;
            </li>
            <li>защиты баз данных;</li>
            <li>
              мониторинга уязвимостей серверов, логирования и анализа сетевой
              активности;
            </li>
            <li>
              обнаружением фактов несанкционированного доступа к персональным
              данным и принятием мер по их устранению и недопущению повтора;
            </li>
            <li>
              восстановлением персональных данных, модифицированных или
              уничтоженных
            </li>
            <li>вследствие несанкционированного доступа к ним;</li>
            <li>
              установлением правил доступа к персональным данным, обрабатываемым
              в информационной системе персональных данных, а также обеспечением
              регистрации и учета всех действий, совершаемых с персональными
              данными в информационной системе персональных данных;
            </li>
            <li>
              проверкой наличия в договорах, заключаемых в Проекте, и включением
              при необходимости в договоры пунктов об обеспечении
              конфиденциальности персональных данных;
            </li>
            <li>
              контролем за принимаемыми мерами по обеспечению безопасности
              персональных данных и уровня защищенности информационных систем
              персональных данных.
            </li>
          </ul>
          <p>
            4.3. Третьи лица, получившие доступ к персональным данным по
            поручению Оператора, обязуются принимать необходимые организационные
            и технические меры к обеспечению конфиденциальности такой информации
            на своих серверах и персональных устройствах, на которых они
            осуществляют обработку персональных данных.
          </p>
          <h2>
            5. Ответственность за разглашение конфиденциальной информации,
            содержащей персональные данные
          </h2>
          <p>
            5.1. Оператор не несет ответственности за возможное нецелевое
            использование персональных данных и причинение какого-либо ущерба
            субъекту персональных данных, произошедшее вследствие:
          </p>
          <ul>
            <li>
              технических неполадок в программном обеспечении и в технических
              средствах и сетях, находящихся вне контроля Оператора;
            </li>
            <li>
              в связи с намеренным или ненамеренным использованием Сайта
              Оператора не по их прямому назначению третьими лицами;
            </li>
            <li>
              необеспечения конфиденциальности паролей доступа или намеренной
              передачи паролей доступа, иной информации с Сайта самим субъектом
              персональных данных при получении услуг Оператора (использовании
              Сайта) другим лицам, не имеющим доступа к данной информации;
            </li>
            <li>
              неправомерных действий третьих лиц по доступу к данным Сайта, в
              т.ч. персональным данным.
            </li>
          </ul>
          <p>
            5.2. Оператор не несет ответственности за обработку персональных
            данных третьих лиц, которые получатель услуг Оператора сообщил как
            свои собственные. Риск привлечения к ответственности в этом случае
            несет получатель услуг Оператора, предоставивший недостоверные
            данные.
          </p>
          <p>
            5.3. Оператор не контролирует и не несет ответственность за
            обработку информации сайтами третьих лиц, на которые субъект
            персональных данных может перейти по ссылкам, доступным на Сайте
            Оператора.
          </p>
          <h2>6. Разрешение споров</h2>

          <p>
            6.1. До обращения в суд с иском по спорам, возникающим из отношений
            между субъектом персональных данных и Оператором, обязательным
            является предъявление претензии (письменного предложения о
            добровольном урегулировании спора).
          </p>
          <p>
            6.2 Получатель претензии в течение 30 (тридцати) календарных дней со
            дня получения претензии, письменно уведомляет заявителя претензии о
            результатах рассмотрения претензии.
          </p>
          <p>
            6.3. При не достижении соглашения спор будет передан на рассмотрение
            в судебный орган по месту регистрации Оператора в соответствии с
            действующим законодательством РФ.
          </p>
          <p>
            6.4. К настоящей Политике конфиденциальности и отношениям между
            субъектом персональных данных и Оператором применяется действующее
            законодательство РФ.
          </p>
          <h2>7. Дополнительные условия</h2>

          <p>
            7.1. Оператор вправе вносить изменения в настоящую Политику
            конфиденциальности без согласия субъектов персональных данных.
          </p>
          <p>
            7.2. Новая Политика конфиденциальности вступает в силу с момента ее
            размещения на сайте, если иное не предусмотрено новой редакцией
            Политики конфиденциальности.
          </p>
          <p>
            7.3. Предложения и замечания для внесения изменений в Политику
            конфиденциальности следует направлять через Центр технической
            поддержки.
          </p>
          <p>
            7.4. Недействительность отдельных норм настоящего Положения, если
            таковое будет признано решением суда или иного уполномоченного
            государственного органа, не влечет ее недействительности в целом.
          </p>
          <p>
            7.5. При использовании обработке персональных данных Оператор не
            осуществляет специально проверку наличия особого режима обработки
            персональных данных, установленного законодательством стран, к
            юрисдикции которого относятся отдельные получатели услуг Оператора
            или лица, предоставившие свои данные по форме подписки на Сайте.
            Если субъект персональных данных является резидентом государства с
            особым режимом защиты персональных данных, например, в Европейской
            экономической зоне (EEA), и получает доступ к Сайту из стран Европы,
            Оператор предпринимает все разумные меры для обеспечения соблюдения
            таких требований законодательства о защите персональных данных,
            установленных соответствующим государством. Для этого субъект
            персональных данных обязан уведомить Оператора о наличии особого
            режима защиты его персональных данных путем обращения Центр
            технической поддержки.
          </p>

          <ListGroup variant="flush">
            <ListGroup.Item>ИП Геттуева Е.П.</ListGroup.Item>
            <ListGroup.Item>ИНН 071002993304</ListGroup.Item>
            <ListGroup.Item>
              Служба поддержки{" "}
              <a
                href="https://desk.zoho.com/portal/areaborealis/ru/kb/astroschool"
                rel="nofollow noreferrer"
                target="_blank"
              >
                https://desk.zoho.com/portal/areaborealis/ru/kb/astroschool
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} md={2} />
      </Row>
    </Container>
  );
};

export default PrivacyPolicyRu;
