import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const { currLang } = props;

  // функция языкового модуля
  const { t } = useTranslation(["translationNavbar"]);

  function drawListItem(key, { link, isExternal, text }) {
    if (isExternal) {
      return (
        <ListGroup.Item key={key} className="bg-light px-0 py-1">
          <a
            href={link}
            target="_blank"
            rel="noreferrer nofollow"
            className="text-decoration-none text-invert"
          >
            {text}
          </a>
        </ListGroup.Item>
      );
    }
    return (
      <ListGroup.Item
        key={key}
        as={Link}
        to={link}
        className="bg-light px-0 py-1 text-invert"
      >
        {text}
      </ListGroup.Item>
    );
  }

  return (
    <React.Fragment>
      <Row className="row-cols-2 row-cols-md-4 mt-5">
        {/* Ресурсы */}
        <Col className="d-flex align-items-start flex-column mb-5">
          <p className="fs-5">
            <small>{t("Resourses")}</small>
          </p>
          <ListGroup variant="flush" style={{ fontSize: "small" }}>
            {[
              {
                text: t("Learn astrology"),
                link: t("linkastro"),
                isExternal: true,
              },
              {
                text: t("Follow us"),
                link: t("followlink"),
                isExternal: true,
              },
            ].map((item, index) => drawListItem(index, item))}
          </ListGroup>
        </Col>

        {/* Политики */}
        <Col className="d-flex align-items-start flex-column mb-5">
          <p className="fs-5">
            <small>{t("Policies")}</small>
          </p>
          <ListGroup variant="flush" style={{ fontSize: "small" }}>
            {[
              { text: t("Terms of Use"), link: `/terms/${currLang}/` },
              { text: t("Privacy Policy"), link: `/privacy/${currLang}/` },
            ].map((item, index) => drawListItem(index, item))}
          </ListGroup>
        </Col>

        {/* Support */}
        <Col className="d-flex align-items-start flex-column mb-5">
          <p className="fs-5">
            <small>{t("Support")}</small>
          </p>
          <ListGroup variant="flush" style={{ fontSize: "small" }}>
            {[
              // {
              //   text: t("Customer Support Portal"),
              //   link: t("portallink"),
              //   isExternal: true,
              // },
              {
                text: t("Send request"),
                link: t("mailto: support@clearastrology.com"),
                isExternal: true,
              },
            ].map((item, index) => drawListItem(index, item))}
          </ListGroup>
        </Col>

        {/* Контакты */}
        <Col className="d-flex align-items-start flex-column mb-5">
          <p className="fs-5">
            <small>{t("Contacts")}</small>
          </p>
          <ListGroup variant="flush" style={{ fontSize: "small" }}>
            {[
              {
                text: t("+1 (302) 467-20-58"),
                link: t("tel"),
                isExternal: true,
              },
              {
                text: t("support@astro-school.org"),
                link: t("mailto: support@astro-school.org"),
                isExternal: true,
              },
            ].map((item, index) => drawListItem(index, item))}
          </ListGroup>
        </Col>
      </Row>
      <Row className="border-top py-3 text-muted">
        <div className="d-flex justify-content-center align-items-center">
          {/* {currLang === "en" && (
            <div className="me-2">
              <img width="24" height="24" src="/assets/blog/img/ca_logo.svg" />
            </div>
          )} */}
          <div className="me-2">{t("Clear Astrology LLC")}</div>
          <div>© {new Date().getFullYear()}</div>
        </div>
      </Row>
    </React.Fragment>
  );
};

Footer.propTypes = {
  /**
   * Текущий язык приложения
   */
  currLang: PropTypes.string,
};

export default Footer;
