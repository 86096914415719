import React from "react";
import PropTypes from "prop-types";
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const NothingFound = (props) => {
  const { currLang } = props;

  const { t } = useTranslation(["translationNavbar"]);

  function redirectToBlogSearch(value) {
    window.location.href = `/blog/${currLang}/?s=${value}`;
    return null;
  }

  return (
    <Container>
      <Row className="mt-5 mg-5">
        <Col xs={12} md={2} />
        <Col xs={12} md={8}>
          <h1>404</h1>
          {t("404body")}
          <div className="bg-light px-3 py-3 my-4 rounded">
            <InputGroup>
              <InputGroup.Text id="search-group">
                <i className="bi bi-search"></i>
              </InputGroup.Text>
              <Form.Control
                placeholder={t("Search the site")}
                aria-label="search"
                aria-describedby="search-group"
                onKeyDown={(e) => {
                  e.code === "Enter" && redirectToBlogSearch(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Col xs={12} md={2} />
    </Container>
  );
};

NothingFound.propTypes = {
  /**
   * Текущия язык приложения
   */
  currLang: PropTypes.string,
};

export default NothingFound;
