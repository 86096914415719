/**
 * Название слоев программы Ольга и пунктов меню
 * в виде константы.
 */
export const olgaLayers = {
  chart: {
    menuItem: "menu.chart",
    menuIcon: "bi-speedometer2",
    new: "menu.new",
    open: "menu.open",
    edit: "menu.edit",
    asImage: "menu.asimage",
    share: "menu.share",
  },
  analysis: {
    menuItem: "menu.analysis",
    menuIcon: "bi-sliders2",
    radicality: "menu.radicality",
    receptions: "menu.receptions",
    parts: "menu.parts",
    stars: "menu.stars",
    planets: "menu.planets",
    hours: "menu.hours",
    signs: "menu.signs",
    elections: "menu.elections",
  },
  aspects: {
    menuItem: "menu.aspects",
    menuIcon: "bi-braces-asterisk",
    exact: "menu.exact",
    longterm: "menu.longterm",
    close: "menu.close",
    antiscia: "menu.antiscia",
    allaspects: "menu.allaspects",
  },
  naturopathy: {
    menuItem: "menu.naturopathy",
    menuIcon: "bi-heart-pulse",
    symptoms: "menu.symptoms",
    healing: "menu.healing",
  },
  upgrade: {
    menuItem: "menu.upgrade",
    menuIcon: "bi-unlock",
  },
};

/**
 * Используются в навигационном меню
 * для подсветки текущих разделов сайта,
 * изменения вида меню или направления
 * на внешний Django ресурс блога
 */
export const pageID = {
  main: "main",
  olga: "olga",
  blog: "blog",
  static: "static",
  e404: "e404",
};

export const dialogueStages = {
  /**
   * Нет активной кнопки для перехода на стадию
   * Приветствие после выбора вопроса
   */
  enquery: "enquery",

  /**
   * Кнопка начала стадии: "Да, все верно"
   * Объяснение того, что гороскоп рождения не нужен
   */
  indoctrination: "indoctrination",

  /**
   * Кнопка начала стадии "Переходим к прогнозу"
   * Объяснение на что будет похож ответ
   */
  description: "description",

  /**
   * Кнопка начала стадии: "Ок, начнем"
   * Объяснение цены вопроса и предложение оплатить
   */
  payment: "payment",

  /**
   * Кнопка начала стадии: "Переходим к оплате"
   * В чате ничего не происходит - оплата в платежном шлюзе
   */
  redirect: "redirect",

  /**
   * Нет кнопок и ничего не происходит в чате.
   * Стадия устанавливается платежным шлюзом при
   * успешной/неуспешной оплате
   */
  paymentFailed: "paymentFailed",
  paymentSuccess: "paymentSuccess",

  /**
   * Кнопка начала стадии: "Платеж не прошел"
   * Приглашение к повторной оплате при неуспехе
   */
  readingPaymentFailed: "readingPaymentFailed",

  /**
   * Кнопка начала стадии: "Я оплатил. Получить отчет"
   * предоставление отчета и заключительное слово
   */
  reading: "reading",

  /**
   * Кнопка начала стадии: "Перейти к отчету"
   * В чате ничего не происходит
   */
  noNextStage: "noNextStage",

  /**
   * Кнопка начала стадии: "Закрыть чат"
   * В чате ничего не происходит
   */
  noNextStagePaimentFailed: "noNextStagePaimentFailed",
};

export const glyphs = ["Q", "W", "E", "R", "T", "Y", "U"];
