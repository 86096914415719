import React from "react";
import { Col, Container, ListGroup, Row } from "react-bootstrap";

const TermsRu = () => {
  return (
    <Container className="my-3 my-md-5">
      <Row className="post">
        <Col xs={12} md={2} />
        <Col xs={12} md={8}>
          <h1>Договор-оферта</h1>
          <hr className="w-25" />
          <p className="pt-0 pb-4">
            <small className="text-muted">
              ИП Геттуева Е.П. ИНН 071002993304
            </small>
          </p>
          <h2>1. Общие положения</h2>
          <p>
            1.1. Настоящий Договор является официальным предложением (публичной
            офертой) индивидуального предпринимателя Геттуевой Елены Петровны
            (далее – «Исполнитель) заключить договор на изложенных ниже условиях
            любому физическому лицу (далее – «Заказчик»). Настоящий документ
            является публичной офертой в соответствии с пунктом 2 статьи 437
            Гражданского Кодекса Российской Федерации.
          </p>
          <p>
            1.2. Лицо, осуществившее акцепт настоящей публичной оферты,
            приобретает все права и обязанности Заказчика, предусмотренные
            настоящим Договором.
          </p>
          <p>
            1.3. Акцептом настоящей публичной оферты является осуществление
            Заказчиком полной или частичной оплаты
            информационно-консультационных услуг, определенных в п. 2.1., в
            соответствии с условиями настоящего Договора. С момента поступления
            денежных средств в счет оплаты оказываемых услуг на расчетный счет
            Исполнителя настоящий Договор считается заключенным между Заказчиком
            и Исполнителем.
          </p>
          <p>
            1.4. Принятие настоящей оферты и соответственно заключение
            настоящего Договора означает, что Заказчик в необходимой для него
            степени ознакомился с условиями настоящего Договора, описанием услуг
            на сайте, определенном в п. 1.7.5, спецификой платежной системы
            сайта, на котором размещаются информационные материалы, признает
            безусловную пригодность оказываемых услуг и сайта для совершения
            действий и достижения целей, являющихся предметом настоящего
            Договора.
          </p>
          <p>
            1.5. Осуществляя акцепт настоящей оферты, Заказчик подтверждает, что
            оказание Исполнителем услуг по настоящему Договору происходит
            дистанционно с использованием клиентского программного обеспечения
            полностью соответствует возможности Заказчика пользоваться
            предлагаемыми Исполнителем услугами.
          </p>
          <p>
            1.6. Актуальный договор-оферта всегда находится по адресу:
            https://pocket-astrologer.com/terms/ru/
          </p>
          <p>
            1.7. В настоящем Договоре, если из его текста прямо не вытекает
            иное, следующие слова и выражения будут иметь указанные ниже
            значения:
          </p>
          <p>
            1.7.1. Договор – настоящий документ, опубликованный в сети Интернет.
          </p>
          <p>
            1.7.2. Пакет услуг (Пакет) – совокупность определенного набора
            информационных материалов, автоматизированных тестов, шаблонов и
            любых иных документов и информации, объединенных общей тематикой и
            единым названием и приобретаемых на Сайте.
          </p>
          <p>
            1.7.3. Клиентское программное обеспечение (ПО) – браузер (Internet
            Explorer, FireFox, Google Chrome и аналогичные) для доступа к
            информационным ресурсам, находящихся в сети Интернет, а также иные
            программы для обработки предоставляемой информации. Заказчик
            обязуется самостоятельно обеспечить наличие ПО на своем персональном
            компьютере.
          </p>
          <p>
            1.7.4. Сайт – принадлежащая Исполнителю совокупность информации,
            текстов, графических элементов, дизайна, изображений, фото и
            видеоматериалов и иных результатов интеллектуальной деятельности, а
            также ссылки доступа к веб-приложениям, содержащихся на сервере в
            сети Интернет по сетевому адресу: https://pocket-astrologer.com/.
          </p>
          <p>
            1.7.5 Все остальные термины и определения, встречающиеся в тексте
            настоящего Договора, толкуются Сторонами в соответствии с
            действующим законодательством Российской Федерации и сложившимися в
            сети Интернет обычными правилами толкования соответствующих
            терминов.
          </p>
          <h2>2.Предмет договора</h2>
          <p>
            2.1. Предметом настоящего Договора является возмездное
            предоставление Заказчику доступа к информационным материалам по теме
            астрологии в виде тестов.
          </p>
          <p>
            2.2. Заказчик оплачивает Услуги, а Исполнитель принимает на себя
            обязательства исполнить такие Услуги.
          </p>
          <p>
            2.3. Темы, стоимость, описание Услуг предоставляется Заказчику в
            ходе диалога с чат-ботом на главной странице Сайта.
          </p>
          <p>
            2.4. Услуга оказывается Исполнителем дистанционно через сеть
            Интернет посредством программного обеспечения.
          </p>
          <h2>3. Сроки оказания услуг</h2>
          <p>
            3.1. Услуги по настоящему Договору оказываются сразу после успешной
            оплаты
          </p>
          <p>
            3.2. Услуги по настоящему Договору считаются оказанными с надлежащим
            качеством и в срок, а также принятыми Заказчиком, если в течение 3
            (трех) календарных дней по истечению срока, указанного в п. 3.1
            настоящего Договора для соответствующей Услуги, Заказчик не заявил
            мотивированного возражения на качество и объем таких Услуг путем
            отправки заявления по адресу support@astro-school.org.
          </p>
          <h2>4. Порядок оказания услуг</h2>
          <p>
            4.1. Исполнитель оказывает Услуги по настоящему Договору в
            соответствии с выбранным Заказчиком Пакетом услуг.
          </p>
          <p>
            4.2. Оказание услуг в виде предоставления доступа к текстовым
            материалам осуществляется Исполнителем автоматически и
            незамедлительно с момента поступления оплаты от Заказчика.
          </p>
          <p>
            4.3. Технически предоставления услуги просиходит следующим образом:
            в окне диалога с чат-ботом на главной странице сайта после оплаты
            появляется индивидуальная ссылка для доступа к материалам.
          </p>
          <p>
            4.4. Стороны пришли к соглашению, что акт сдачи-приемки в отношении
            Услуг, оказываемых по настоящему Договору не оформляется. Отсутствие
            претензий со стороны Заказчика, направленных в письменной форме в
            адрес Исполнителя в течение 3 (трех) календарных дней с даты
            завершения Пакета услуг в целом (согласно п. 3.1. Договора), будет
            считаться подтверждением факта полной и безоговорочной приемки по
            качеству и объему Услуг, оказанных в рамках такого Пакета услуг в
            целом.
          </p>
          <h2>5. Права и обязанности сторон</h2>
          <h3>5.1. Исполнитель обязуется</h3>
          <p>
            5.1.1. Оказать Услуги надлежащим образом и в установленные сроки.
          </p>
          <p>
            5.1.2. Не разглашать информацию о персональных данных Заказчика без
            его согласия и осуществлять их обработку исключительно с целью
            исполнения настоящего Договора в соответствии с Политикой
            конфиденциальности, принятой Исполнителем.
          </p>
          <h3>Исполнитель вправе</h3>
          <p>
            5.2.1.Отказать в предоставлении услуг Заказчику в случае
            непоступления оплаты по выбранной Услуге в установленные сроки.
          </p>
          <p>
            5.2.2. Требовать от Заказчика добросовестного исполнения взятых на
            себя обязательств, уважительного отношения к Исполнителю.
          </p>
          <p>
            5.2.3. Изменять условия настоящего Договора в одностороннем порядке,
            без предварительного согласования с Заказчиком, обеспечивая при этом
            публикацию измененных условий на Сайте не менее чем за один день до
            их введения в действие.
          </p>
          <p>
            5.2.4. Дополнять в одностороннем порядке существующие информационные
            материалы.
          </p>
          <p>
            5.2.5. В одностороннем порядке расторгнуть настоящий Договор в
            случае существенного нарушения Заказчиком условий настоящего
            Договора. При этом денежные средства, оплаченные Заказчиком по
            настоящему Договору, возврату не подлежат и являются штрафной
            неустойкой за действия Заказчика.
          </p>
          <p>
            Под существенным нарушением условий настоящего Договора понимается
            любое нарушение авторских прав, регламентированных действующим
            законодательством РФ об авторском праве, в том числе однократное
            нарушение Заказчиком п. 5.3.7. настоящего Договора.
          </p>
          <p>
            По усмотрению Исполнителя в зависимости от характера нарушения
            существенным может быть признано любое нарушение Заказчиком правил,
            установленных п. 5.3.4. – 5.3.11. настоящего Договора, совершенное
            однократно и/или более двух раз.
          </p>
          <p>
            5.2.6. Привлекать для оказания Услуг в соответствии с настоящим
            Договором третьих лиц.
          </p>
          <h3>5.3. Заказчик обязуется</h3>
          <p>
            5.3.1. Самостоятельно знакомиться с описанием соответствующей Услуги
            в окне диалога с чат-ботом.
          </p>
          <p>
            5.3.2. Иметь персональный компьютер или иное портативное (мобильное)
            устройство с доступом в сеть Интернет с установленным клиентским ПО,
            необходимым и достаточным для получения приобретенной Услуги.
          </p>
          <p>
            5.3.3. Своевременно производить оплату Услуг по настоящему Договору
            в установленные сроки.
          </p>
          <p>
            5.3.4. Не изменять каким бы то ни было способом программную часть
            сайта, с которого осуществляется оказание Услуг, не совершать
            какие-либо действия, направленные на изменение функционирования и
            работоспособности сайта.
          </p>
          <h3>5.4. Заказчик вправе</h3>
          <p>
            5.4.1.Требовать надлежащего исполнения Исполнителем своих
            обязательств по настоящему Договору.
          </p>
          <p>
            5.4.2. Получать информацию по любым вопросам, касающимся организации
            исполнения Услуг по настоящему Договору.
          </p>
          <h2>6. Стоимость услуг и порядок их оплаты</h2>
          <p>
            6.1. Стоимость информационно-консультационных услуг по настоящему
            Договору указывается на странице с описанием соответствующей Услуги
            и может быть изменена Исполнителем в любое время в одностороннем
            порядке. Новая стоимость вступает в силу с момента опубликования и
            не распространяется на оплаченные к моменту опубликования таких
            изменений Услуги.
          </p>
          <p>6.2. Оплата выбранной услуги производится Заказчиком сразу </p>
          <p>
            6.3. Моментом оплаты считается поступление денежных средств на счет
            платежной системы, которую использует Исполнитель для приема
            платежей.
          </p>
          <h2>7. Условия и порядок возврата денежных средств</h2>
          <p>
            7.1. Заказчик вправе обратиться с заявлением о возврате денежных
            средств по приобретенной Услуге до того момента, пока услуга не была
            оказана полностью.
          </p>
          <p>
            7.2. Принимая решение о возврате, Исполнитель вправе удержать часть
            стоимости Услуг для возмещения фактических затрат, которые он понес
            на момент получения заявления о возврате.
          </p>
          <p>
            7.3. К фактическим затратам Исполнителя относятся расходы,
            совершенные на момент получения заявления о возврате, в частности:
          </p>
          <ul>
            <li>
              стоимость предоставленного Заказчику доступа к информационным
              материалам,
            </li>
            <li>
              расходы на использованное Исполнителем серверных мощностей и
              коммерческого ПО или оплату услуг третьих лиц,
            </li>
            <li>
              комиссии банковских, кредитных организаций и соответствующих
              платежных систем за осуществление приема денежных средств.
            </li>
          </ul>
          <p>
            Конкретная сумма фактических затрат для каждого случая определяется
            Исполнителем самостоятельно.
          </p>
          <p>
            В том случае, если Заказчик не использовал предоставленные
            материалы, стоимость таких материалов и услуг также подлежит
            включению в состав расходов Исполнителя, в связи с обстоятельствами,
            не зависящими от воли Исполнителя.
          </p>
          <p>
            7.4. Требование о возврате оплаченных сумм, предъявленное Заказчиком
            позже срока, установленного пунктом 7.1. настоящего Договора, то
            есть когда услуга была оказана полностью, Исполнителем
            рассматривается, но не подлежит удовлетворению, так как:
          </p>
          <ul>
            <li>
              односторонний отказ от договора, который был фактически исполнен,
              не допускается действующим законодательством РФ;
            </li>
            <li>
              такой отказ в удовлетворении обусловлен необходимостью выплаты
              определенных денежных сумм третьим лицам, участвующим в
              предоставлении Заказчику Пакета услуг.
            </li>
          </ul>
          <p>
            7.5. Все заявления о возврате денежных средств принимаются только на
            почту Исполнителя support@astro-school.org.
          </p>
          <p>7.6. Обязательные пункты заявления на возврат денежных средств:</p>
          <ul>
            <li>наименование адресата и данные заявителя;</li>
            <li>
              наименование Пакета услуг, по которому заявляется отказ от
              Договора и возврат оплаты,
            </li>
            <li>сумма оплаты Услуг, причины, побудившие вернуть деньги;</li>
          </ul>
          <p>
            7.7. Решение о возврате или об отказе в возврате денежных средств
            принимается Исполнителем в течение 3 (трех) дней с момента получения
            соответствующего заявления Заказчика.
          </p>
          <p>
            7.8. Денежные средства возвращаются на счет Заказчика, привязанного
            к банковской карте, с которой производилась оплата, в течение 7
            (семи) дней после принятия решения о возврате.
          </p>
          <p>
            7.9. В случае удовлетворения заявления о возврате денежных средств
            доступ к информационным материалам для Заказчика прекращается в
            течение 1 (одного) рабочего дня с даты направления Исполнителем
            Заказчику решения о соответствующем возврате. Указанное требование
            Заказчика о возврате также считается отзывом заранее данного
            акцепта, указанного в пункте 1.3. настоящего Договора.
          </p>
          <h2>8. Ответственность</h2>
          <p>
            8.1. В случае нарушения условий настоящего Договора, стороны несут
            ответственность согласно действующему законодательству Российской
            Федерации.
          </p>
          <p>
            8.2. Исполнитель не несет ответственности за невозможность оказания
            услуг Заказчику по причинам, не зависящим от Исполнителя, а именно:
            нарушение работы Интернета, оборудования или ПО со стороны
            Заказчика, сбои в работе служб email-рассылки, в том числе при
            попадании писем Исполнителя в папку “Спам” или указания Заказчиком
            неверного email-адреса. В данном случае услуги считаются оказанными
            надлежащим образом и подлежащим оплате в полном размере.
          </p>
          <p>
            8.3. Заказчик соглашается, что вся полученная информация несет
            исключительно развлекательный характер ы рамках оказания Услуг по
            настоящему Договору и не может рассматриваться совет, инструкция к
            действию или как гарантии достижения какого-либо результата.
          </p>
          <p>
            8.4. Стороны освобождаются от ответственности за неисполнение или
            ненадлежащее исполнение обязательств по настоящему Договору на время
            действия непреодолимой силы. В течение этого времени Стороны не
            имеют взаимных претензий и каждая из сторон принимает на себя свой
            риск последствия форс–мажорных обстоятельств. О возникновении таких
            обстоятельств Исполнитель обязан уведомить Заказчика путем
            размещения информации на Сайте и/или на электронный почтовый ящик,
            указанный Заказчиком при совершении оплаты, а Заказчик обязан
            направить Исполнителю письмо на электронную почту
            support@astro-school.org с указанием в строке тема сообщения
            “Форс-мажор”.
          </p>
          <p>
            Под обстоятельствами непреодолимой силы (форс-мажорными
            обстоятельствами) Стороны понимают: пожар, наводнение,
            землетрясение, забастовки и другие стихийные бедствия, война и
            военные действия, вступление в силу нормативных правовых актов и
            актов применения права, препятствующих исполнению обязательств,
            вынужденная срочная (неплановая) госпитализация, подтвержденная
            документально, если вышеперечисленные обстоятельства находятся вне
            контроля Сторон, препятствуют выполнению настоящего Договора и
            возникли после заключения настоящего Договора. Отсутствие времени у
            Заказчика по любым основаниям для изучения предоставленных
            информационных материалов, нахождение в отпуске, командировке,
            неоплата доступа к сети Интернет, поломка средства доступа к сети
            Интернет не являются обстоятельствами непреодолимой силы
            (форс-мажорными обстоятельствами).
          </p>
          <p>
            8.5. Совокупная ответственность Исполнителя по настоящему Договору
            по любому иску или претензии в отношении Договора или его исполнения
            ограничивается суммой платежа, уплаченного Исполнителю Заказчиком.
            При этом с Исполнителя может быть взыскан только реальный ущерб, но
            не упущенная выгода.
          </p>
          <h2>9. Персональные данные и их использование</h2>
          <p>
            9.1. Заказчик дает свое согласие Исполнителю на обработку своих
            персональных данных, предоставленных при покупке Услуг по настоящему
            Договору.
          </p>
          <p>
            9.2. Обработка персональных данных означает запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение персональных
            данных, не подпадающих под специальные категории, на обработку
            которых согласно действующему законодательству Российской Федерации,
            требуется письменное согласие Заказчика.
          </p>
          <p>
            9.3. Обработка персональных данных производится в целях исполнения
            Исполнителем обязательств по настоящему Договору, обеспечения
            Заказчика обратной связью при использовании Сайта, а также в целях
            направления на указанный Заказчиком при регистрации (покупке Услуг)
            адрес электронной почты информационных и маркетинговых сообщений.
          </p>
          <p>
            9.4. Обработка персональных данных Заказчика производится
            Исполнителем с использованием баз данных, размещенных на территории
            Российской Федерации.
          </p>
          <p>
            9.5. Заказчик может в любое время отозвать согласие на обработку
            персональных данных, направив Исполнителю соответствующее
            уведомление на адрес: support@astro-school.org. При этом Заказчик
            понимает и признает, что отзыв на обработку персональных данных
            может потребовать удаления любой информации, касающейся участия
            Заказчика на Сайте, в том числе прекращения доступа к информационным
            материалам.
          </p>
          <p>
            9.6. Заказчик дает согласие на получение информационных рассылок и
            маркетинговых материалов от Исполнителя на адрес электронной почты,
            а также технических уведомлений о статусе заказа на номер телефона,
            указанные Заказчиком при оформлении заказа на Сайте.
          </p>
          <p>
            Согласие на получение информационных рассылок может быть отозвано
            Заказчиком в любое время путем нажатия на кнопку отписки в любом из
            полученных электронных писем.
          </p>
          <p>
            9.7. Заказчик дает свое согласие на использование его изображения в
            качестве фотографии Заказчика (аватара) Исполнителем на
            безвозмездной основе.
          </p>
          <h2>10. Защита авторских прав</h2>
          <p>
            10.1. Сайт, с которого осуществляется продажа Услуг Исполнителя или
            непосредственное оказание таких Услуг содержит результаты
            интеллектуальной деятельности, принадлежащие Исполнителю, его
            аффилированным лицам и другим связанным сторонам, представителям,
            всем прочим лицам, действующим от имени Исполнителя.
          </p>
          <p>
            10.2. Используя Сайт (сайты) Исполнителя, Заказчик признает и
            соглашается с тем, что все содержимое Сайта (сайтов) и структура его
            содержимого защищены авторским правом, правом на товарный знак и
            другими правами на результаты интеллектуальной деятельности, и что
            указанные права являются действительными и охраняются во всех
            формах, на всех носителях и в отношении всех технологий, как
            существующих в настоящее время, так и разработанных или созданных
            впоследствии. Никакие права на любое содержимое Сайта Исполнителя,
            включая, помимо прочего, аудиовизуальные произведения, текстовые и
            графические материалы, программы для ЭВМ, товарные знаки не
            переходят к Заказчику в результате пользования Сайтом и заключения
            настоящего Договора.
          </p>
          <p>
            10.3. При цитировании материалов Сайта Исполнителя Заказчик
            обязуется указывать ссылку на Сайт.
          </p>
          <p>
            10.4. В случае нарушения Заказчиком положений настоящего Договора,
            касающихся защиты авторских прав Исполнителя, последний вправе
            потребовать компенсации всех причиненных убытков, включая упущенную
            выгоду.
          </p>
          <h2>11. Заключительные положения</h2>
          <p>
            11.1. Настоящий Договор вступает в силу с момента акцепта Заказчика
            в соответствии с п. 1.3. Договора и действует до полного исполнения
            обязательств сторонами.
          </p>
          <p>
            11.2. По всем вопросам, не урегулированным настоящим Договором,
            стороны руководствуются действующим законодательством Российской
            Федерации.
          </p>
          <p>
            11.3. Все споры и разногласия между сторонами разрешаются путем
            переговоров. При этом претензионный порядок разрешения споров,
            возникающим из отношений по поводу заключения настоящего Договора,
            является обязательным.
          </p>
          <p>
            Получатель претензии в течение 30 (тридцати) календарных дней со дня
            получения претензии, письменно уведомляет заявителя претензии о
            результатах рассмотрения претензии.
          </p>
          <p>
            При недостижении соглашения в установленный настоящем пункте срок
            спор передается на рассмотрение в судебный орган в соответствии с
            действующим законодательством РФ по месту регистрации Исполнителя.
          </p>
          <p>
            11.4. Признание судом какого-либо положения настоящего Договора
            недействительным или не подлежащим принудительному исполнению не
            влечет недействительности иных его положений.
          </p>
          <p>
            11.5. Настоящим Стороны подтверждают, что при исполнении настоящего
            Договора, а также при ведении переписки между Сторонами, допускается
            использование аналогов собственноручной подписи Сторон. Стороны
            подтверждают, что все уведомления, сообщения, соглашения и документы
            в рамках исполнения Сторонами обязательств, возникших из настоящего
            Договора, подписанные с помощью обмена электронными документами,
            содержащими скан собственноручной подписи Сторон, имеют юридическую
            силу и обязательны для исполнения Сторонами, при условии, что при
            этом используются уполномоченные адреса электронной почты и учетные
            данные к Личному кабинету.
          </p>
          <p>
            11.6. Стороны признают, что все уведомления, сообщения, соглашения,
            документы и письма, направленные с использованием уполномоченных
            адресов электронной почты и Личного кабинета, считаются
            направленными и подписанными Сторонами, кроме случаев, когда в таких
            письмах прямо не указано обратное.
          </p>
          <p>
            11.7. Уполномоченными адресами электронной почты Сторон признаются:
          </p>

          <ul>
            <li>для Исполнителя support@astro-school.org.</li>
            <li>
              для Заказчика: адрес электронной почты, указанный при покупке
              Услуги.
            </li>
            <li>
              11.8. Стороны обязуются обеспечивать конфиденциальность сведений и
              информации, необходимых для доступа к уполномоченным адресам
              электронной почты и Личному кабинету Заказчика, не допускать
              разглашение такой информации и передачу третьим лицам. Стороны
              самостоятельно определяют порядок ограничения доступа к такой
              информации.
            </li>
          </ul>
          <p>
            11.9. До момента получения от Заказчика информации о нарушения
            режима конфиденциальности, все действия и документы, совершенные и
            направленные с помощью уполномоченного адреса электронной почты
            Заказчика и Личного кабинета, даже если такие действия и документы
            были совершены и направлены иными лицами, считаются совершенными и
            направленными Заказчиком. В этом случае права и обязанности, а также
            ответственность наступают у Заказчика.
          </p>
          <p>
            11.10. До момента получения от Исполнителя информации о нарушения
            режима конфиденциальности, все действия и документы, совершенные и
            направленные с помощью его уполномоченного адреса электронной почты,
            даже если такие действия и документы были совершены и направлены
            иными лицами, считаются совершенными и направленными Исполнителем.
          </p>
          <p>
            11.11. Стороны установили, что скриншоты переписки по электронной
            почте, совершенной по реквизитам, указанным в настоящем Договоре, во
            исполнение настоящего Договора, являются достаточным и допустимым
            доказательством для подтверждения тех фактов, которые в них указаны,
            без специального нотариального удостоверения таких фактов и
            скриншотов.
          </p>
          <p>Реквизиты Исполнителя</p>
          <ListGroup variant="flush">
            <ListGroup.Item>ИП Геттуева Е.П.</ListGroup.Item>
            <ListGroup.Item>ИНН 071002993304</ListGroup.Item>
            <ListGroup.Item>
              Служба поддержки{" "}
              <a
                href="https://desk.zoho.com/portal/areaborealis/ru/kb/astroschool"
                rel="nofollow noreferrer"
                target="_blank"
              >
                https://desk.zoho.com/portal/areaborealis/ru/kb/astroschool
              </a>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col xs={12} md={2} />
      </Row>
    </Container>
  );
};

export default TermsRu;
